<template>
  <div>
    <KTCodePreview :title="titlePage">
      <template v-slot:preview>
        <div>
          <b-row class="mb-5">
            <b-col>
              <b-input
                placeholder="Tìm kiếm theo Id tham chiếu"
                v-model="apiParams.referenceId"
                size="sm"
                autocomplete="off"
              ></b-input>
            </b-col>
            <b-col>
              <b-form-select
                class="select-style"
                v-model="apiParams.module"
                :options="moduleOptions"
                size="sm"
              >
              </b-form-select>
            </b-col>
            <b-col>
              <b-form-select
                class="select-style"
                v-model="apiParams.isResolved"
                :options="resolvedStatusOptions"
                size="sm"
              >
              </b-form-select>
            </b-col>
            <b-col>
              <b-form-select
                v-model="apiParams.exceptionFrom"
                :options="exceptionFromList"
                size="sm"
                class="select-style"
              >
              </b-form-select>
            </b-col>
            <b-col>
              <b-button
                style="width: 70px"
                size="sm"
                variant="primary"
                class="font-weight-bolder"
                @click="onSearchFastSyncLog"
              >
                <i class="fas fa-search text-white fa-lg"></i>Lọc
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-pagination-nav
                pills
                v-model="paginationOption.currentPage"
                v-show="paginationOption.hasShow"
                :number-of-pages="paginationOption.numberOfPages"
                use-router
                :link-gen="linkGen"
                align="right"
                class="mt-2"
              />
            </b-col>
          </b-row>
          <b-table
            :items="getListFastSyncLogResponse.data"
            :fields="fields"
            bordered
            hover
          >
            <template #cell(id)="row">

              <div
                class="show-error-detail text-primary font-weight-bold"
                @click="showDetailError(row.item.id)"
              >
                <span>{{ row.item.id }}</span> <i class="fa-solid fa fa-external-link fa-sm text-primary"></i>
              </div>
            </template>

            <template #cell(referenceId)="row">
              <div
                class="font-weight-bold text-center d-flex justify-content-center align-items-center"
                @click="copyReferenceId(row.item.referenceId)"
              >
                <span class="d-inline-block">{{ row.item.referenceId }}</span> <i
                  class="far fa-copy text-primary cursor-pointer ml-2"
                  style="font-size: 0.95rem"
                ></i>
              </div>
            </template>

            <template #cell(action)="row">
              <span v-if="row.item.isResolved > 1">
                <b-button
                  size="sm"
                  variant="primary"
                  title="Đồng bộ lại"
                  v-b-tooltip.right
                  @click="retrySyncToFastByErrorId(row.item.id)"
                >
                  <i
                    class="fa-solid fas fa-redo-alt "
                    style="font-size: 1rem; padding-right: 0px"
                  ></i>
                </b-button>
              </span>
            </template>

            <template #cell(isResolved)="row">
              <span :class="`label label-inline label-pill label-rounded font-weight-bold text-sm ${row.item.statusNameClass}`">{{ row.item.statusName }}</span>
            </template>

            <template #cell(moduleName)="row">
              <span><b v-html="row.item.moduleName.split('-').join(`<br />&#187;&#187;`)"></b></span>
              <!-- <br />
              <span>({{ row.item.module }})</span> -->
            </template>

            <template #cell(fullResponse)="row">
              <pre id="input-json">{{  row.item.fullResponse ? row.item.fullResponse.data : '' }}</pre>
            </template>

            <template #cell(fullRetryResponse)="row">
              <pre id="input-json">{{  row.item.fullRetryResponse ? row.item.fullRetryResponse.data : '' }}</pre>
            </template>
          </b-table>

          <b-row>
            <b-col>
              <p>Tổng số: {{ formatPrice(getListFastSyncLogResponse.total) }}</p>
            </b-col>
            <b-col>
              <b-pagination-nav
                pills
                v-model="paginationOption.currentPage"
                v-show="paginationOption.hasShow"
                :number-of-pages="paginationOption.numberOfPages"
                use-router
                :link-gen="linkGen"
                align="right"
              />
            </b-col>
          </b-row>
        </div>

        <b-modal
          ref="error-detail-modal"
          hide-footer
          title="Chi tiết lỗi"
        >
          <v-form>
            <div>
              <p><span class="font-weight-bold">Đường dẫn: </span>{{ previewDetail.url }}</p>
              <p><span class="font-weight-bold">Phương thức: </span><span class="label label-warning label-inline font-weight-bold">{{ previewDetail.method }}</span></p>
              <b-form-group
                label="Thời gian tạo/ cập nhật"
                label-class="font-weight-bold"
              >
                {{ previewDetail.createdAt }} <i class="text-dark-75 fa fa-arrows-alt-h mx-2"></i> {{ previewDetail.updatedAt }}
              </b-form-group>

              <b-form-group
                label="Kết quả lần đầu"
                label-class="font-weight-bold"
              >
                <pre id="input-json">{{ previewDetail.response ? previewDetail.response.data : '' }}</pre>

              </b-form-group>

              <b-form-group
                label="Kết quả thử lại"
                label-class="font-weight-bold"
              >
                <pre id="input-json">{{  previewDetail.retryResponse ? previewDetail.retryResponse : '' }}</pre>

              </b-form-group>

              <b-form-group
                label="Đầu vào"
                label-for="input-json"
                label-class="font-weight-bold"
              >
                <pre id="input-json">{{ previewDetail.payload }}</pre>
              </b-form-group>

              <b-button
                style="width: 70px"
                variant="primary"
                size="sm"
                @click="hideDetailErrorModal"
              >Đóng</b-button>
            </div>
          </v-form>
        </b-modal>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import {
  formatDate,
  formatPrice,
  makeToastFaile,
  copyClipBoard,
} from '@/utils/common';
import { DEFAULT_PAGINATION } from '@/utils/constants';
import { DP_CONFIG } from '@/utils/date';
import { removeNullish } from '@/utils/normalizes';
import { checkPermission } from '@/utils/saveDataToLocal';
import {
  EXCEPTION_FROM_OPTIONS,
  MODULE_OPTIONS,
  RESOLVED_STATUS_OPTIONS,
  TABLE_FIELDS,
} from '@/utils/types';

const KTCodePreview = () =>
  import(/* webpackPrefetch: true */ '@/view/content/CodePreview.vue');

export default {
  components: {
    KTCodePreview,
  },
  data() {
    return {
      dpConfigs: DP_CONFIG,
      apiParams: {
        fromDate: null,
        toDate: null,
        referenceId: null,
        page: DEFAULT_PAGINATION.PAGE,
        module: null,
        isResolved: null,
        exceptionFrom: null,
      },
      previewDetail: {
        payload: null,
        url: null,
        method: null,
        createdAt: null,
        updatedAt: null,
        retryResponse: null,
        response: null,
      },
      fields: TABLE_FIELDS,
      resolvedStatusOptions: RESOLVED_STATUS_OPTIONS,
      exceptionFromList: EXCEPTION_FROM_OPTIONS,
      moduleOptions: MODULE_OPTIONS,
      isLoading: false,
      getListFastSyncLogResponse: {
        data: [],
        total: 0,
        totalPage: 0,
      },
      currentRouteName: this.$route.name,
    };
  },
  watch: {
    queryParamGetListFastSyncLog: {
      async handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;

        this.setApiParams(newVal);
        await this.getListFastSyncLog(newVal);
      },
      deep: true,
    },
  },
  computed: {
    titlePage() {
      return 'Trạng thái đồng bộ với Fast';
    },
    routeQuery() {
      const query = this.$route.query || {};
      return {
        page: query.page || 1,
        fromDate: query.fromDate || '',
        toDate: query.toDate || '',
        referenceId: query.referenceId || null,
        module: query.module || null,
        isResolved: query.isResolved || null,
        exceptionFrom: query.exceptionFrom || null,
      };
    },
    paginationOption() {
      return {
        hasShow: this.getListFastSyncLogResponse.totalPage >= 2,
        numberOfPages: Number(this.getListFastSyncLogResponse.totalPage) || 1,
        page: this.routeQuery.page,
        size: DEFAULT_PAGINATION.SIZE,
        currentPage: this.routeQuery.page,
      };
    },
    queryParamGetListFastSyncLog() {
      const queryParam = {
        page: this.routeQuery.page,
        size: 10,
        fromDate: this.routeQuery.fromDate,
        toDate: this.routeQuery.toDate,
        module: this.routeQuery.module,
        isResolved: this.routeQuery.isResolved,
        referenceId: this.routeQuery.referenceId,
        exceptionFrom: this.routeQuery.exceptionFrom,
      };

      return this.removeNullish(queryParam);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Trạng thái đồng bộ với Fast', route: '/fast-sync' },
    ]);
  },
  async created() {
    await this.getListFastSyncLog(this.queryParamGetListFastSyncLog);
  },
  methods: {
    makeToastFaile,
    formatPrice,
    formatDate,
    checkPermission,
    removeNullish,

    linkGen(pageNum) {
      return {
        name: this.currentRouteName,
        query: { ...this.getFilterParams({ page: pageNum }) },
      };
    },

    isEmptyObject(object) {
      return object && Object.keys(object).length > 0;
    },

    setApiParams(queryParam) {
      this.apiParams = {
        fromDate: queryParam.fromDate,
        toDate: queryParam.toDate,
        referenceId: queryParam.referenceId || null,
        page: queryParam.page || null,
        module: queryParam.module || null,
        isResolved: queryParam.isResolved || null,
        exceptionFrom: queryParam.exceptionFrom || null,
      };
    },

    getFilterParams(filterOption) {
      const queryParam = {
        ...this.routeQuery,
        ...this.apiParams,
        ...filterOption,
      };

      const nilFilterParam = this.removeNullish(queryParam);
      return nilFilterParam;
    },

    onSearchFastSyncLog() {
      const queryParam = this.getFilterParams({ page: 1 });
      this.$router
        .push({ name: 'fast-sync-list', query: queryParam })
        .catch(() => {});
    },

    async getListFastSyncLog(queryParam = {}) {
      if (this.isLoading) return;
      this.isLoading = true;

      try {
        const response = await this.$api.fastSync.getList(queryParam);
        const { data, total, totalPage } = response.data.data;

        this.getListFastSyncLogResponse = Object.assign(
          {},
          this.getListFastSyncLogResponse,
          {
            data,
            total,
            totalPage,
          },
        );

        this.isLoading = false;
      } catch (error) {
        this.makeToastFaile(error);
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },

    async retrySyncToFastByErrorId(id) {
      if (this.isLoading) return;
      this.isLoading = true;
      try {
        await this.$api.fastSync.retrySyncDataErrorById(id);
        await this.getListFastSyncLog();
      } catch (error) {
        await this.getListFastSyncLog();
        this.makeToastFaile(error.message);
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },

    mappingDetailError(id) {
      const detail = this.getListFastSyncLogResponse.data.find(
        (item) => item.id === id,
      );

      this.previewDetail.payload = detail.payload;
      this.previewDetail.url = detail.url;
      this.previewDetail.method = detail.method
        ? detail.method.toUpperCase()
        : '';
      this.previewDetail.createdAt = detail.createdAt;
      this.previewDetail.updatedAt = detail.updatedAt;
      this.previewDetail.response = detail.response;
      this.previewDetail.retryResponse = detail.retryResponse;
    },

    showDetailError(id) {
      this.mappingDetailError(id);
      this.$refs['error-detail-modal'].show();
    },

    hideDetailErrorModal() {
      this.$refs['error-detail-modal'].hide();
    },

    readMore(fullResponse) {
      const parsedStringResponse = JSON.stringify(fullResponse);
      if (parsedStringResponse && parsedStringResponse.length > 200) {
        return parsedStringResponse.slice(0, 200) + ' ...';
      }
      return parsedStringResponse;
    },

    copyReferenceId(referenceId) {
      if (referenceId && referenceId.length > 8) {
        copyClipBoard(referenceId, 'Id tham chiếu');
      }
    },
  },
};
</script>

<style scoped>
.show-error-detail:hover {
  text-decoration: underline;
  cursor: pointer;
}

table {
  table-layout: fixed;
  word-wrap: break-word;
}

.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
</style>
